export default function Footer() {
  return (
    <footer class="footer footer-center p-10 text-base-content rounded">
      <div class="grid grid-flow-col gap-4"></div>
      <div>
        <a href="https://quack.software">
          <p class="font-bold">QuackWare LLC</p>
        </a>
        <p>Copyright © {new Date().getFullYear()} - All right reserved</p>
      </div>
    </footer>
  );
}
