import { useCallback, useState } from "preact/hooks";
import ServiceCard from "../components/ServiceCard";
import { ServiceData } from "../types";
import { createRef } from "preact";
import ContactForm from "../components/ContactForm";

const SERVICE_DATA: ServiceData[] = [
  {
    id: "full_stack_software_development",
    title: "Full Stack Software Development",
    description: "Full Stack Software Development covering a wide range of tech stacks.",
  },
  {
    id: "mvp_development",
    title: "MVP Development",
    description: "We help early stage startups architect, develop, and deploy their initial viable product.",
  },
  {
    id: "aws_infrastructure_automation",
    title: "AWS Infrastructure Automation",
    description: "We can automate your AWS deployments and infrastructure provisioning using AWS CDK.",
  },
  {
    id: "etl_data_pipelines",
    title: "ETL & Data Pipelines",
    description:
      "We have extensive experience integrating hundreds of data sources and sinks and building reliable, performant data pipelines.",
  },
  {
    id: "general_devops_consulting",
    title: "General DevOps Consulting",
    description:
      "We can setup and manage your CI/CD processes and infrastructure across a wide range of cloud providers.",
  },
  {
    id: "code_review_project_estimation",
    title: "Code Review and Project Estimation",
    description:
      "We can perform code reviews of your software projects and produce detailed estimates for fixing potential issues, upgrade recommendations, and more.",
  },
];

export default function Services() {
  const servicesModal = createRef<HTMLDialogElement>();
  const [initialSubject, setInitialSubject] = useState("");
  const [serviceId, setServiceId] = useState("");

  const onLearnMoreClicked = useCallback(
    (service: ServiceData) => {
      setInitialSubject(service.title);
      setServiceId(service.id);
      if (servicesModal.current) {
        servicesModal.current.showModal();
      }
    },
    [servicesModal]
  );

  const onServicesModalCloseClicked = useCallback(() => {
    if (servicesModal.current) {
      servicesModal.current.close();
    }
  }, [servicesModal]);

  return (
    <div class="pt-8 pb-20 px-4 flex flex-col">
      <div class="relative max-w-lg mx-auto lg:max-w-7xl mb-10">
        <div class="flex flex-row">
          <h2 class="flex-none text-3xl tracking-tight text-primary sm:text-4xl">Software and Consulting Services</h2>
        </div>
        <div class="divider"></div>
        <div class="mt-4 grid sm:gap-4 pt-4 sm:grid-cols-3">
          {SERVICE_DATA.map((service, idx) => (
            <ServiceCard key={idx} service={service} onLearnMoreClicked={() => onLearnMoreClicked(service)} />
          ))}
        </div>
      </div>
      <dialog id="servicesModal" class="modal" ref={servicesModal}>
        <div class="modal-box">
          <h3 class="font-bold text-lg">Learn More</h3>
          <p class="py-4">
            Please provide any information regarding your interest in this service. We will get back to you as soon as
            possible!
          </p>
          <ContactForm
            contactId={serviceId}
            initialSubject={initialSubject}
            onCloseClicked={onServicesModalCloseClicked}
          />
        </div>
      </dialog>
    </div>
  );
}
