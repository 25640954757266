import { ServiceData } from "../types";

export interface ServiceCardProps {
  service: ServiceData;
  onLearnMoreClicked: () => void;
}

export default function ServiceCard({ service, onLearnMoreClicked }: ServiceCardProps) {
  return (
    <div key={service.title} class="card w-96 bg-base-300 shadow-xl">
      <div class="card-body">
        <h2 class="text-xl text-accent card-title">{service.title}</h2>
        <p class="mt-3 text-base">{service.description}</p>
        <div class="mt-4 flex items-center">
          <button class="btn btn-primary" onClick={onLearnMoreClicked}>
            Learn More
          </button>
        </div>
      </div>
    </div>
  );
}
