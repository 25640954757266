import { createRef } from "preact";
import { useCallback } from "preact/hooks";
import ContactForm from "../components/ContactForm";

export default function Home() {
  const contactModal = createRef<HTMLDialogElement>();

  const onContactClicked = useCallback(() => {
    if (contactModal.current) {
      contactModal.current.showModal();
    }
  }, [contactModal]);

  const onContactModalCloseClicked = useCallback(() => {
    if (contactModal.current) {
      contactModal.current.close();
    }
  }, []);

  return (
    <div class="hero min-h-[70vh]">
      <div class="hero-content flex-col text-center">
        <div class="max-w-md">
          <img src="/logo.png" class="w-36 h-36 mx-auto" />
          <h1 class="text-6xl font-bold">QuackWare</h1>
          <h2 class="text-2xl py-4 text-accent">Full Stack Software and DevOps Consulting</h2>
        </div>
        <div class="flex flex-row">
          <p class="text-primary px-2">
            <a class="btn btn-primary" href="/services">
              Services
            </a>
          </p>
          <p class="text-primary px-2">
            <a alt="Contact" class="btn btn-primary" href="mailto:curtis@quack.software">
              Contact
            </a>
          </p>
          <p class="text-primary px-2">
            <a href="https://github.com/quackware" target="_blank" alt="GitHub" class="btn btn-primary">
              GitHub
            </a>
          </p>
        </div>
      </div>
      <dialog id="contactModal" class="modal" ref={contactModal}>
        <div class="modal-box">
          <h3 class="font-bold text-lg">Contact Form</h3>
          <p class="py-4">
            Please provide your email and enter a message below. We will get back to you as soon as possible!
          </p>
          <ContactForm contactId="homepage_contact" onCloseClicked={onContactModalCloseClicked} />
        </div>
      </dialog>
    </div>
  );
}
