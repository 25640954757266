import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "./Layout.tsx";
import Home from "./pages/Home.tsx";
import Services from "./pages/Services.tsx";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path={"/"} element={<Home />} />
          <Route path={"/services"} element={<Services />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
