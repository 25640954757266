import Navbar from "./components/Navbar.tsx";
import { NavbarItemProps } from "./components/NavbarItem.tsx";
import Footer from "./containers/Footer.tsx";
import { Outlet } from "react-router-dom";

const NAVBAR_ITEMS: NavbarItemProps[] = [
  {
    href: "/services",
    text: "Services",
    active: window.location.pathname === "/services",
  },
];

export default function Layout() {
  return (
    <div>
      <Navbar
        logo={new URL("../../../public/logo.png", import.meta.url).pathname}
        title="QuackWare"
        href="/"
        items={NAVBAR_ITEMS}
      />
      <Outlet />
      <Footer />
    </div>
  );
}
